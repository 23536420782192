<template>
  <div class="personal">
    <!-- 订单信息 -->
    <div class="personalA clearfix">
      <div class="personal_title clearfix">
        <span style="padding-left: 15px">订单详情</span>
      </div>
      <div>
        <div class="personal_img clearfix">
          <img :src="list.SerImgUrl"
            alt=""
            style="width: 65px; height: 65px" />
        </div>
        <div class="personal_info clearfix">
          <div style="position: relative">
            <span>下单人 :{{ list.OMName }}</span>
          </div>
          <div style="margin: 4px 0px">类别 :{{ list.OTypeDesc }}</div>
          <div style="margin: 4px 0px">总数 :{{ list.SerNum }} <span style="padding-left:10px;">金额 :</span>
            <span>¥{{ list.Amount }}元</span></div>
          <div style="margin: 4px 0px">状态 :{{ list.StatusDesc }}</div>
          <div style="margin: 4px 0px">{{ list.OMAddr }}</div>
        </div>
      </div>
    </div>
    <!-- 配送信息 -->
    <!-- <div class="personalA clearfix">
      <div class="personal_title">
        <span style="padding-left: 15px">{{ list.StaName }}</span>
      </div>
      <div class="personal_MX clearfix">
        <span class="clearfix">社区</span>
        <span class="clearfix">{{ list.OrgName }}</span>
      </div>
      <div class="personal_MX clearfix">
        <span class="clearfix">小区</span>
        <span class="clearfix">{{ list.RName }}</span>
      </div>
      <div class="personal_MX clearfix">
        <span class="clearfix">服务站联系人</span>
        <span class="clearfix">{{ list.StaTouchMan }}</span>
      </div>
      <div class="personal_MX clearfix">
        <span class="clearfix">配送时间</span>
        <span class="clearfix">{{ list.DelTime }}</span>
      </div>
      <div class="personal_MX clearfix">
        <span class="clearfix">订单状态</span>
        <span class="clearfix">{{ list.StatusDesc }}</span>
      </div>
      <div class="personal_MX clearfix">
        <span class="clearfix">备注</span>
        <span class="clearfix" v-if="list.Remark">{{ list.Remark }}</span>
        <span class="clearfix" v-else>无</span>
      </div>
    </div> -->
    <!-- 服务保障 -->
    <!-- <div class="personalA clearfix">
      <div class="personal_title"><span style="padding-left: 15px;">服务保障</span></div>
      <div class="personal_MX clearfix">
        <span class="clearfix">号码保护</span>
        <span class="clearfix">隐藏手机号保护客户隐私,通话录音,服务护航</span>
      </div>
    </div> -->
    <!-- 订单信息 -->
    <div class="personalA clearfix">
      <div class="personal_title">
        <span style="padding-left: 15px">服务项目</span>
      </div>
      <!-- <div class="personal_MX clearfix">
        <span class="clearfix">订单类型</span>
        <span class="clearfix">{{ list.OTypeDesc }}</span>
      </div>
      <div class="personal_MX clearfix">
        <span class="clearfix">下单时间</span>
        <span class="clearfix">{{ list.OrderTime }}</span>
      </div> -->
      <!-- 服务列表 -->
      <div v-for="item in fuwulist"
        :key="item.index">
        <div class="personal_img clearfix">
          <img :src="item.SImgUrl"
            alt=""
            style="width: 65px; height: 65px" />
        </div>
        <div class="personal_info clearfix">
          <div style="position: relative">
            <span>名称 :{{ item.SName }}</span>
          </div>

          <div style="margin: 4px 0px">分类 :{{ item.SerKindDesc }}</div>
          <div style="margin: 4px 0px">数量 :{{ item.SNum }}</div>
          <div style="margin: 4px 0px">
            金额 :{{ item.SAmount }}元
          </div>

        </div>
      </div>
      <div class="personal_Buttton clearfix">
        <a :href="'tel:' + list.OMMobile">
          <button style="background: #07c160; margin-left: 10%; float: left">
            联 系 商 家
          </button>
        </a>
        <a :href="'tel:' + list.StaTouchTel">
          <button style="background: #1989fa; margin-right: 10%; float: right">
            联 系 顾 客
          </button>
        </a>
      </div>
    </div>
    <div class="order"
      v-if="list.Status == 2">
      <button @click="order(list.OrderId)">接 单</button>
    </div>
    <!-- 服务站选择器 -->
    <van-popup v-model="marriageShow"
      position="bottom"
      get-container="body">
      <van-picker show-toolbar
        :columns="marriageData"
        value-key="Title"
        @cancel="marriageShow = false"
        @confirm="orderOn">
      </van-picker>
    </van-popup>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
Vue.use(Toast);
Vue.use(Dialog);
import { getToken, setToken } from "@/utils/auth";
import {
  WeGetStaOrder,
  WeRecviceOrder,
  GetOrderServiceList,
  GetYLOrganList,
} from "@/api/Lycal.js";
export default {
  data () {
    return {
      acctoken: "",
      list: [],
      fuwulist: [],
      marriageData: [], //服务站列表
      marriageShow: false, //服务站弹框
    };
  },
  mounted () {
    this.acctoken = getToken();
    this.getlist();
    this.getYLOrganList()
  },
  methods: {
    // 确认接单
    // order() {
    //   Dialog.confirm({
    //     title: "提示",
    //     message: "是否确认接单!",
    //   })
    //     .then(() => {
    //       WeRecviceOrder({
    //         accToken: this.acctoken,
    //         OrderId: this.$route.params.id,
    //       }).then((res) => {
    //         if (res.data.code == 0) {
    //           Toast.success("接单成功!");
    //           this.getlist();
    //         } else {
    //           Toast.fail(res.data.msg);
    //         }
    //       });
    //     })
    //     .catch(() => {
    //       // on cancel
    //     });
    // },
    order (row) {
      console.log(row);
      this.marriageShow = true;
      this.orderId = row;
    },
    // 接单
    orderOn (val) {
      var staId = val.SSId;
      var orderId = this.orderId;
      Dialog.confirm({
        title: "提示",
        message: "是否确认指定派单!",
      }).then(() => {
        WeRecviceOrder({
          accToken: this.acctoken,
          OrderId: orderId,
          StaId: staId,
        }).then((res) => {
          if (res.data.code == 0) {
            Toast.success("派单成功!");
            this.getlist();
            this.marriageShow = false;
          } else {
            Toast.fail(res.data.msg);
          }
        });
      });
    },
    // 获取服务站列表
    getYLOrganList () {
      var oCode = this.postOrderId;
      var Token = this.acctoken;
      GetYLOrganList({ accToken: Token, orgCode: oCode, ssLevel: 2 }).then(
        (res) => {
          this.marriageData = res.data.data;
        }
      );
    },
    // 获取分页
    getlist () {
      WeGetStaOrder({
        accToken: this.acctoken,
        orderId: this.$route.params.id,
      }).then((res) => {
        console.log(res);
        this.list = res.data.data;
      });

      GetOrderServiceList({
        accToken: this.acctoken,
        orderId: this.$route.params.id,
      }).then((res) => {
        this.fuwulist = res.data.data;
      });
    },
  },
};
</script>
<style scoped>
.personal {
  background: #f7f7f9;
}
.personalA {
  background: #fff;
  width: 90%;
  margin: 15px 5%;
  border-radius: 10px;
  padding-bottom: 15px;
  box-shadow: 0px 0px 5px #ddd;
}
.personalA .personal_title {
  height: 45px;
  width: 100%;
  line-height: 45px;
  border-bottom: 1px solid #ccc;
}
.personalA .personal_img {
  float: left;
  margin: 10px 15px;
}
.personalA .personal_info {
  width: 61%;
  margin: 10px 15px;
  float: left;
  font-size: 14px;
  color: #333;
}
.personalA .personal_MX {
  float: left;
  width: 100%;
  font-size: 14px;
  color: #333;
}
.personalA .personal_MX span:nth-child(1) {
  float: left;
  display: inline-block;
  margin: 8px 15px;
  width: 25%;
}
.personalA .personal_MX span:nth-child(2) {
  float: right;
  margin: 8px 15px;
  display: inline-block;
  width: 55%;
}
.personalA .personal_Buttton {
  width: 100%;
}
.personalA .personal_Buttton button {
  width: 30%;
  border: none;
  padding: 8px 10px;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
}
.order {
  width: 100%;
}
.order button {
  width: 90%;
  margin: 0 5% 15px;
  border: none;
  padding: 8px 10px;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  height: 45px;
  background: #3497fb;
}
</style>